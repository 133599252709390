/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://iswoc23ogbebzngjynb6jral5a.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-2:de77cb93-c57a-4891-98f7-bbdc2a7618ea",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_w3esE9CrG",
    "aws_user_pools_web_client_id": "3o9rs7uv88kim5rr2f1p1i6u37",
    "oauth": {}
};


export default awsmobile;
