import React from "react";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify'
import awsmobile from "../aws-exports";

import Header from "../components/template/Header";
import Container from "../components/template/Container";
import Column from "../components/template/Column";
import Typography from "../components/common/Typography";
import Link from "../components/common/Link";
import Separator from '../components/template/Separator'
import TextInput from '../components/form/TextInput'
import Button from '../components/form/Button'
import Alert from '../components/common/Alert'
import ActivityIndicator from '../components/common/ActivityIndicator'
import successIcon from '../assets/success.png'
import errorIcon from '../assets/error.png'

Amplify.configure(awsmobile);

const guestSignIn = async () => {
  const guestkey = 'Z3Vlc3RAZXhhbXBsZS5jb20gbm90X2Ffc2VjcmV0'
  const guest = window.atob(guestkey).split(' ')
  const auth = await Auth.signIn(guest[0], guest[1])
}

const findDoctor = async (name) => {
  await guestSignIn()

  const listDoctorsQuery = `{ findDoctor(full_name: "${name}") { full_name } }`

  const foundDoctor = await API.graphql(graphqlOperation(listDoctorsQuery))
    .then(result => {
      const { data } = result
      return data && data.findDoctor && data.findDoctor.full_name === name
    })
    .catch(err => console.log('err', err))

  return foundDoctor
}

const VerifyForm = () => {
  const [input, setInputValue] = React.useState(null)
  const [isVerified, setIsVerified] = React.useState(null)
  const [canRenderAnswer, setCanRenderAnswer] = React.useState(false)
  const [searching, setSearching] = React.useState(false)

  const handleNameChange = e => {
    const value = e.target.value;
    if(value && value.length > 0){
      setInputValue(value.trim())
      setCanRenderAnswer(false)
    }else{
      setInputValue(null)
      setCanRenderAnswer(false)
    }
  }

  const handleVerify = async () => {
    if (input) {
      const sanitizedText = input.toLowerCase()
      setSearching(true);
      setCanRenderAnswer(true)

      const searchResult = await findDoctor(sanitizedText)
      setIsVerified(searchResult)
      setSearching(false)
    }
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      handleVerify()
    }
  } 

  const renderAnswer = () => {
    if(input && canRenderAnswer && !searching){
      if(isVerified){
        return (
          <Alert success>
              <Column row>
                <Column row width={'10%'}>
                  <img src={successIcon} width={'75%'} />
                </Column>
                <Column width={'1%'} />
                <Column row width={'89%'}>
                  The doctor {`"${input}"`} is registered.
                </Column>
              </Column>
          </Alert>
        )
      }else{
        return (
          <Alert error>
            <Column flex padding={'20'}>
              <Column row>
                  <Column padding={'3px 0 0 0'} column width={'8%'} flexAlign={'flex-start'} height={'100%'} justify={'flex-start'}> 
                    <img src={errorIcon} width={'80%'} />
                  </Column>
                  <Column column width={'4%'} />
                  <Column column width={'87%'}>
                    Sorry! We couldn’t find a record of {`'${input}'`}.
                    <br/>
                    <br/>
                    If a prescriber is not certified the pharmacist should:
                    <br/>
                    <br/>
                    <Column row flexAlign={'flex-start'}>
                      <Column width={'5%'}>1.</Column>
                      <Column width={'95%'}>
                        Contact the non-certified prescriber to remind them of the TGA requirements for JINARC® and check whether JINARC® should still be dispensed to the patient.
                        
                      </Column>
                    </Column>
                    <br/>
                    <br/>
                    <Column row flexAlign={'flex-start'}>
                      <Column width={'5%'}>2.</Column>
                      <Column width={'95%'}>
                      Contact Otsuka Medical Information on <Link href={'tel:1800 059 606'} color={'#fff'} underline>1800 059 606</Link>. Otsuka will then educate and certify the prescriber.
                      </Column>
                    </Column>
                  </Column>
              </Column>
            </Column>
          </Alert>
        )
      }
    }

    return <React.Fragment />
  }

  const title = 'JINARC® (tolvaptan) Verify'

  return (
    <Container>
      <Header title={title} />
      <Column
          hideInDesktop
          showInMobile
          flexCenter
          width={"100%"}
          align={"center"}
      >
          <Typography mobileSize={18} center titleSection bold>
              {title}
          </Typography>
      </Column>

      <Separator size={20} />
      <Typography center size={14}>
        Please verify that the prescriber is certified by cross checking the JINARC® prescriber database.
      </Typography>

      <Column column block margin={'30px 0'} mobileMargin={'25px 0'} padding={'20px 60px 30px 60'} mobilePadding={'20px 15px 30px 15'}>
        <Typography size={14}>Enter the doctor's first name then last name without their title, e.g. John Smith</Typography>
        <Separator size={25} />
        <Column row>
          <TextInput noLabel noSeparator placeholder={'John Smith'} onKeyDown={handleKeyDown} onChange={handleNameChange} />
          <Button disabled={!input || searching} onClick={handleVerify}>{searching ? <ActivityIndicator /> : 'VERIFY'}</Button>
        </Column>
        {renderAnswer()}
      </Column>

      <Separator size={5} />
      <Column column mobilePadding={'0px 15'}>
        <Typography size={11} color={'#0E264D'} lineHeight={20}>
          JINARC® is a registered trademark of Otsuka Pharmaceutical Co., Ltd. IMADJIN® is a registered trademark of Otsuka Australia Pharmaceutical Pty Ltd. © Otsuka Australia Pharmaceutical Pty Ltd, ABN 20 601 768 754. Chatswood NSW 2067. Medical Information: <Link size={11} href="tel:1800 059 606">1800 059 606</Link>. <Link size={11} href="https://www.otsuka.com.au" target={'_blank'}>www.otsuka.com.au</Link>. September 2020. JIN-2009-46.00.
        </Typography>
      </Column>
    </Container>
  );
};

export default VerifyForm;
